/* Center the page contents vertically and horizontally */
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Use viewport height for full centering */
  text-align: center;
  padding: 20px; /* Prevents content from touching edges */
}

/* Style for the logo */
/* Style for the container */
.content-container {
    background-color: #ffffff;
    /* Add background color as needed */
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    /* Add box shadow as needed */
    margin-top: 60px;
    /* Space between image and container */
}

/* Add a class for red text color */
.error-message {
    color: red;
    font-size: 24px;
  }


  .button {
    margin-top: 50px;
    background-color: #4386ff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Add hover effect */
  .button:hover {
    background-color: #1e64d1; /* Darker shade of blue on hover */
  }

  .message-container {
    display: flex; /* Use flex layout to align items horizontally */
    align-items: center; /* Vertically center-align items */

  }
  
  .status-image {
    width: 72px; /* Adjust the width of the alert image as needed */
    height: auto; /* Maintain the aspect ratio */
    margin-right: 10px; /* Add margin to separate the alert image from the message */
  }
  
  .success-message {
    color: green;
    font-size: 24px;
  }

  /* Hide the image and container by default */
.alert-image,
.content-container {
  display: none;
}


/* Show the content container when it should appear */
.content-container.show {
    display: block;
    opacity: 1;
    transition: opacity 0.5s; /* Add a smooth transition effect */
  }
  